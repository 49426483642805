<template>
  <b-list-group>
    <b-list-group-item
      v-for="(item,key) in interviewPoll.poll"
      :key="key"
    >
      <div class="font-weight-bold text-primary mb-1">
        {{ item.questions }}
      </div>
      <v-select
        v-model="interview.poll[key]"
        :options="item.options"
        placeholder="Seçiniz"
      />
    </b-list-group-item>
  </b-list-group>

</template>
<script>
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'InterviewPolls',
  components: {
    BListGroup,
    BListGroupItem,
    vSelect,
  },
  props: {
    idComBrand: {
      type: String,
      required: true,
    },
    idComInterviewSubject: {
      type: String,
      required: true,
    },
  },
  computed: {
    interviewPoll() {
      return this.$store.getters['interviewPolls/getInterviewPoll']
    },
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
  },
  watch: {
    interviewPoll: {
      deep: true,
      handler(val) {
        if (val.pollcode) {
          this.interview.pollcode = val.pollcode
          this.interview.interview_poll = this.interviewPoll
        } else {
          this.interview.pollcode = null
          this.interview.interview_poll = {}
        }
      },
    },
  },
  created() {
    this.getInterviewPoll()
  },
  methods: {
    getInterviewPoll() {
      this.$store.dispatch('interviewPolls/interviewPoll', {
        id_com_brand: 6,
        id_com_interview_subject: 17,
      })
    },
  },
}
</script>
<style scoped></style>
