<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-row class="w-100 d-flex justify-content-center align-items-center">
          <b-col>
            <b-input-group>
              <BFormInput
                v-model="search"
                placeholder="Müşteri Adı, plaka, Telefon No, Görüşme No.."
                @keydown.enter="searchData"
              />
              <b-input-group-append>
                <b-button
                  v-if="search"
                  variant="warning"
                  @click="resetSearch"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
                <b-button
                  variant="outline-primary"
                  @click="searchData"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <b-form-checkbox
              v-model="deletedSwitch"
              value="'0'"
              switch
            >
              Silinenleri Göster
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card-header>
      <template v-if="!loading">
        <template v-if="dataList.length > 0">
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            fixed
          >
            <template #cell(customer_name)="data">
              {{ data.item.customer_name }}
              <div v-if="data.item.company_name">
                <small class="text-primary">{{ data.item.company_name }}</small>
              </div>
              <div v-if="data.item.username1">
                <small class="text-warning"><b>Satış Temsilcisi: </b>{{ data.item.username1 }}</small>
              </div>
            </template>
            <template #cell(interview_number)="data">
              <div>
                <small class="text-warning">{{ data.item.interview_number }}</small>
              </div>
              {{ data.item.interview_status }}
              <div>
                <small class="text-info">{{ data.item.interview_subject }}</small>
              </div>
              <div>
                <small class="text-primary">{{ data.item.license_plate }}</small>
              </div>
            </template>
            <template #cell(username)="data">
              {{ data.item.username }}
              <div>
                <small class="text-warning">{{ moment(data.item.created).format('DD.MM.YYYY') }}</small>
              </div>
              <div v-if="data.item.deleted">
                <small><b>Silinme: </b><span class="text-danger">{{ moment(data.item.deleted).format('DD.MM.YYYY') }}</span></small>
              </div>
            </template>
            <template #cell(control)="data">
              <template v-if="!data.item.deleted">
                <b-button
                  variant="flat-primary"
                  class="btn-icon rounded-circle"
                  @click="getInterviewData(data.item.interview_number)"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  @click="removeData(data.item.id,data.item.id_com_user)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </template>
          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCount"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </template>
        <b-alert
          v-else
          show
          variant="info"
          class="m-2"
        >
          <div class="alert-body text-center">
            Tamamlanmış anket bulunmuyor.
          </div>
        </b-alert>
      </template>
      <template v-else>
        <pre-loading class="mb-2" />
      </template>
    </b-card>
    <b-modal
      v-model="modalStatus"
      centered
      title="İkinci El 45. Gün Anketi Düzenle"
      size="xl"
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
    >
      <survey-form />
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BCardFooter,
  BCardHeader,
  BPagination,
  BTable,
  BButton,
  BAlert,
  BFormCheckbox,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BModal,
} from 'bootstrap-vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'
import SurveyForm from '@/views/I2Surveys/Completed/SurveyForm.vue'

export default {
  name: 'CompletedList',
  components: {
    BModal,
    SurveyForm,
    PreLoading,
    BAlert,
    BCardFooter,
    BPagination,
    BTable,
    BCard,
    BCardHeader,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      loading: false,
      deletedSwitch: false,
      search: '',
      modalStatus: false,
      fields: [
        {
          key: 'customer_name',
          label: 'Müşteri',
        },
        {
          key: 'interview_number',
          label: 'Görüşme',
        },
        {
          key: 'username',
          label: 'Danışman',
          thStyle: { width: '200px' },
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '140px' },
        },
      ],
      dataQuery: {
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['i2surveys/getI2Completeds']
    },
    dataCount() {
      return this.$store.getters['i2surveys/getI2CompletedCount']
    },
    getInterview() {
      return this.$store.getters['interviews/getInterview']
    },
    getCarCard() {
      return this.$store.getters['i2surveys/getCarCard']
    },
    saveStatus() {
      return this.$store.getters['i2surveys/getI2SaleSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
    deletedSwitch(val) {
      if (val) {
        this.dataQuery.deleted = true
      } else if (this.dataQuery.deleted) {
        delete this.dataQuery.deleted
      }
      this.getDataList()
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.modalStatus = false
        this.getDataList()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.loading = true
      this.$store.dispatch('i2surveys/i2Completeds', this.dataQuery)
        .then(() => {
          this.loading = false
        })
    },
    resetSearch() {
      this.search = ''
      this.searchData()
    },
    searchData() {
      const orLike = {}
      if (this.search) {
        orLike['com_customer.name'] = this.search
        orLike['com_customer.company_name'] = this.search
        orLike['com_customer.phone'] = this.search
        orLike['com_cars.license_plate'] = this.search
        orLike['com_interview.interview_number'] = this.search
        this.dataQuery.or_like = orLike
      } else if (this.dataQuery.or_like) {
        delete this.dataQuery.or_like
      }
      this.getDataList()
    },
    removeData(id, idComUser) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('interviews/removeData', { id, id_com_user: idComUser })
            .then(res => {
              if (res) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: this.$store.state.app.removeResultMessage,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getDataList()
              }
            })
        }
      })
    },
    getInterviewData(interviewNumber) {
      this.$store.dispatch('i2surveys/getInterview', { interview_number: interviewNumber })
        .then(response => {
          if (response) {
            // interview
            this.getInterview.id = response.id
            this.getInterview.interview_number = response.interview_number
            this.getInterview.content = response.content
            this.getInterview.id_com_interview_type = response.id_com_interview_type
            this.getInterview.id_com_interview_subject = response.id_com_interview_subject
            this.getInterview.id_com_interview_status = response.id_com_interview_status
            this.getInterview.id_com_meet = response.id_com_meet
            this.getInterview.id_com_cars = response.id_com_cars
            this.getInterview.id_com_customer = response.id_com_customer
            this.getInterview.id_com_brand = response.id_com_brand
            this.getInterview.id_com_user1 = response.id_com_user1
            this.getInterview.chasis = response.chasis
            this.getInterview.poll = response.poll
            if (this.getInterview.id_com_user1) {
              this.getInterview.updateb2sales = true // interview save ye b2 sales updatesi
            }
            // car card
            this.getCarCard.customer = response.company_name ? response.company_name : response.customer_name
            this.getCarCard.phone = response.phone
            this.getCarCard.email = response.email
            this.modalStatus = true
          }
        })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
